import {
  FETCH_SCHEDULED_EMAILS,
  FETCH_SCHEDULED_EMAILS_SUCCESS,
  DELETE_SCHEDULED_EMAIL_SUCCESS,
  CLONE_AND_DELETE_SCHEDULED_EMAIL
} from '../../constants/actionTypes/scheduledEmails'

const ACTION_HANDLERS = {
  [FETCH_SCHEDULED_EMAILS]: (state, action) => {
    // On fetch request, reset the state
    return initialState
  },
  [FETCH_SCHEDULED_EMAILS_SUCCESS]: (state, action) => {
    const { data } = action

    return {
      ...data
    }
  },
  [DELETE_SCHEDULED_EMAIL_SUCCESS]: (state, action) => {
    const { id } = action.data

    const scheduledEmails = { ...state }
    delete scheduledEmails[id]

    return scheduledEmails
  },
  [CLONE_AND_DELETE_SCHEDULED_EMAIL]: (state, action) => {
    const { id } = action

    const scheduledEmails = { ...state }
    delete scheduledEmails[id]

    return scheduledEmails
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = null

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
