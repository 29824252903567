import { checkAndRefreshUserToken } from './auth'
import { API_SUCCESS, API_REQUEST, API_ERROR } from '../constants/actionTypes/notifications'
import adminApi from '../utils/apis/admin'

export const importAndIndex = (indexName) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.importAndIndex(indexName))
      .then((res) => {
        const { totalIndexed, total } = res.data

        dispatch({
          type: API_SUCCESS,
          alert: {
            type: 'success',
            message: `${totalIndexed} out of ${total} ${indexName} have been indexed.`
          }
        })

        if (totalIndexed < total) {
          dispatch({
            type: API_ERROR,
            alert: {
              type: 'error',
              message: `Not all of your ${indexName} were indexed, check the logs to find out what happened.`
            }
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: `Error with indexing ${indexName}. Please refresh and try again.`
          }
        })
      })
  }
}

export const deleteIndex = (indexName) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.deleteIndex(indexName))
      .then((res) => {
        dispatch({
          type: API_SUCCESS,
          alert: {
            type: 'success',
            message: `Your ${indexName} index has been deleted.`
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: `Error with deleting ${indexName} index. Please refresh and try again.`
          }
        })
      })
  }
}
