import axios from 'axios'
import apiConfig from '../config'
import { getHeaders } from '../auth'

const { apiUrl, admins } = apiConfig
const adminsUrl = `${apiUrl}${admins.url}`
const mailUrl = `${adminsUrl}${admins.mail.url}`

export const sendHtmlEmail = (data) => {
  return axios.post(mailUrl, data, {
    headers: getHeaders()
  })
}

export const sendWelcomeEmail = (data) => {
  const url = `${mailUrl}${admins.mail.welcome}`

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

export const sendPersonalitySummaryEmail = (data) => {
  const url = `${mailUrl}${admins.mail.personalitySummary}`

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

export default {
  sendHtmlEmail,
  sendWelcomeEmail
}
