import React from 'react'
import ReactDOM from 'react-dom'
import rootReducer from './reducers'
import createStore from './store/createStore'
import { createBrowserHistory } from 'history'
import AppContainer from './containers/AppContainer'
import { routes } from './routes'

// ========================================================
// Create browser history
// ========================================================
const history = createBrowserHistory({
  basename: '/'
})

// ========================================================
// Route setup, this is required for hot module reload in dev mode
// ========================================================
const getRoutes = () => {
  if (__DEV__) {
    // HMR requires a fresh initialization of routes
    return require('./routes').default()
  }

  return routes()
}

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
export const store = createStore(initialState, history, rootReducer)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('admin-panel-root')

let render = () => {
  ReactDOM.render(
    <AppContainer store={store} routes={getRoutes()} history={history} />,
    MOUNT_NODE
  )
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./routes', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    )
  }
}

// ========================================================
// Go!
// ========================================================
render()
