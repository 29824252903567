import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import notifications from './notifications'
import user from './user'
import newsletter from './newsletter'
import mail from './mail'
import transcripts from './transcripts'
import features from './features'
import accounts from './accounts'

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  newsletter,
  mail,
  transcripts,
  notifications,
  features,
  accounts
})

export default rootReducer
