import { checkAndRefreshUserToken } from '../auth'
import {
  UPDATE_EMAILS,
  UPDATE_SUBJECT,
  UPDATE_BODY,
  SEND_EMAIL_SUCCESS,
  CLEAR_EMAIL_COMPOSE
} from '../../constants/actionTypes/mail'
import adminApi from '../../utils/apis/admin'
import { API_REQUEST, API_ERROR } from '../../constants/actionTypes/notifications'

export const updateEmails = (emails) => {
  return {
    type: UPDATE_EMAILS,
    emails
  }
}

export const updateSubject = (subject) => {
  return {
    type: UPDATE_SUBJECT,
    subject
  }
}

export const updateBody = (body) => {
  return {
    type: UPDATE_BODY,
    body
  }
}

export const clear = () => {
  return {
    type: CLEAR_EMAIL_COMPOSE
  }
}

export const sendEmail = (emails, subject, body) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    const requestData = {
      emails,
      subject,
      body
    }

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.sendHtmlEmail(requestData))
      .then((res) => {
        const { data } = res

        dispatch({
          type: SEND_EMAIL_SUCCESS,
          createdDate: data.createdDate
        })
      })
      .catch(() => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to send this email. Please try again'
          }
        })
      })
  }
}
