import {
  FETCH_NEWSETTER_HISTORY,
  FETCH_NEWSETTER_HISTORY_SUCCESS
} from '../../constants/actionTypes/newsletterHistory'

const ACTION_HANDLERS = {
  [FETCH_NEWSETTER_HISTORY]: (state, action) => {
    // On fetch request, reset the state
    return initialState
  },
  [FETCH_NEWSETTER_HISTORY_SUCCESS]: (state, action) => {
    const { data } = action

    return {
      ...data
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = null

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
