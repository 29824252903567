import { push, replace } from 'connected-react-router'

export const navigate = (nextPath, isReplace = false) => {
  return (dispatch) => {
    if (isReplace) {
      dispatch(replace(nextPath))
    } else {
      dispatch(push(nextPath))
    }
  }
}
