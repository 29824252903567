import { checkAndRefreshUserToken } from './auth'
import { API_SUCCESS, API_REQUEST, API_ERROR } from '../constants/actionTypes/notifications'
import {
  FETCH_FEATURES,
  FETCH_FEATURES_SUCCESS,
  ADD_FEATURE_SUCCESS,
  UPDATE_FEATURE_FLAG_SUCCESS,
  DELETE_FEATURE_FLAG_SUCCESS
} from '../constants/actionTypes/features'
import adminApi from '../utils/apis/admin'

export const fetchFeatures = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEATURES
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.getFeatures())
      .then((res) => {
        const { data } = res

        dispatch({
          type: FETCH_FEATURES_SUCCESS,
          data
        })
      })
  }
}

export const addFeatureFlag = (featureName, flagName, flagData) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    const featureData = {
      flagName,
      ...flagData
    }

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.createFeature(featureName, featureData))
      .then((res) => {
        const { data } = res

        dispatch({
          type: ADD_FEATURE_SUCCESS,
          data
        })
      })
      .catch((err) => {
        const status = err.response.status
        let message = 'Something went wrong when trying to add this feature'

        if (status === 409) {
          message = 'This feature name already exists'
        }

        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: message
          }
        })
      })
  }
}

export const updateFeatureFlag = (featureName, flagName, flagData) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.updateFeatureFlag(featureName, flagName, flagData))
      .then((res) => {
        const { data } = res
        const result = data[flagName]

        dispatch({
          type: UPDATE_FEATURE_FLAG_SUCCESS,
          flagName,
          featureName,
          result
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to update this feature'
          }
        })
      })
  }
}

export const deleteFeatureFlag = (featureName, flagName) => {
  return (dispatch) => {
    dispatch({
      type: API_REQUEST
    })

    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.deleteFeatureFlag(featureName, flagName))
      .then((res) => {
        dispatch({
          type: DELETE_FEATURE_FLAG_SUCCESS,
          featureName,
          flagName
        })

        dispatch({
          type: API_SUCCESS,
          alert: {
            type: 'success',
            message: `${flagName} deleted`
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Error deleting this feature flag'
          }
        })
      })
  }
}
