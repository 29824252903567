import { combineReducers } from 'redux'
import compose from './compose'
import contacts from './contacts'

const mailReducer = combineReducers({
  compose,
  contacts
})

export default mailReducer
