import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import {
  HOME,
  FEATURES,
  SEARCH,
  ACCOUNT,
  MAIL,
  RETOOL
} from '../../constants/routes'
import styles from './SiteWrapper.scss'

const navItems = {
  // nav links
  [HOME.url]: { value: 'Home', to: HOME.url, icon: 'fal fa-home' },
  [RETOOL.url]: { value: 'Retool', to: RETOOL.url, icon: 'fab fa-codepen' },
  [ACCOUNT.simulator]: { value: 'Account Simulator', to: ACCOUNT.simulator, icon: 'fal fa-window' },
  [SEARCH.playground]: { value: 'Elastic Search', to: SEARCH.playground, icon: 'fal fa-search' },
  [FEATURES.url]: { value: 'Feature Flags', to: FEATURES.url, icon: 'fal fa-flag' },
  [MAIL.playground]: { value: 'Mail', to: MAIL.playground, icon: 'fal fa-envelope' }
}

const externalLinks = {
  gsuiteDashboard: { value: 'GSuite Dashboard', to: 'https://gsuite.google.com/dashboard' },
  wikiLink: { value: 'Hipherd Wiki', to: 'https://wiki.hipherd.com' },
  retoolLink: { value: 'Retool', to: 'https://propellant.tryretool.com' },
  status: { value: 'Status', to: 'https://status.hipherd.com/' }
}

class SiteWrapper extends Component {
  handleSignout = (evt) => {
    evt.preventDefault()
    this.props.signOut()
  }

  state = {
    selected: 'home'
  }

  componentDidMount () {
    const { pathname } = this.props.location
    const navItem = navItems[pathname]

    if (navItem) {
      this.setState({
        selected: pathname
      })
    }
  }

  handleNavSelect = (selected) => {
    const navLink = navItems[selected]

    if (!navLink) {
      const externalLink = externalLinks[selected]

      if (externalLink) {
        window.open(externalLink.to, '_blank')
      }
    } else if (navLink.to) {
      this.props.navigate(navLink.to)
    } else {
      this.setState({
        selected
      })
    }
  }

  renderNavItems = () => {
    return Object.keys(navItems).map((key) => {
      const { value, icon } = navItems[key]

      return (
        <NavItem key={`nav-item-${key}`} eventKey={key} title={value}>
          <NavIcon><i className={icon} /></NavIcon>
          <NavText>{value}</NavText>
        </NavItem>
      )
    })
  }

  renderExternalLinks = () => {
    const externalNavLinks = Object.keys(externalLinks).map((key) => {
      const { value } = externalLinks[key]

      return (
        <NavItem key={`nav-item-${key}`} eventKey={key}>
          <NavText>{value}</NavText>
        </NavItem>
      )
    })

    return (
      <NavItem eventKey='importantLinks'>
        <NavIcon><i className='fal fa-external-link' /></NavIcon>
        <NavText title='Important Links'>Important Links</NavText>
        {externalNavLinks}
      </NavItem>
    )
  }

  renderFooterNav = () => {
    const name = this.props.user ? this.props.user.displayName : ''
    const avatarURL = this.props.user ? this.props.user.photoURL : ''
    const avatarStyle = {
      backgroundImage: `url(${avatarURL})`
    }
    const iconStyle = {
      opacity: 1
    }

    return (
      <Nav className='mt-auto pt-5'>
        <NavItem>
          <NavIcon style={iconStyle}><span className='avatar align-middle' style={avatarStyle} /></NavIcon>
          <NavText>{name}</NavText>
        </NavItem>
        <NavItem onClick={this.handleSignout} title='Log out'>
          <NavIcon style={iconStyle}><i className='fal fa-sign-out' /></NavIcon>
          <NavText>Log out</NavText>
        </NavItem>
      </Nav>
    )
  }

  render () {
    const sideNavStyle = {
      background: styles.backgroundColor
    }

    return (
      <div className='d-flex flex-fill overflow-hidden'>
        <SideNav
          className='d-flex flex-column position-relative sidenav-container'
          onSelect={this.handleNavSelect}
          style={sideNavStyle}>
          <Toggle style={{ minHeight: '64px' }} />
          <Nav selected={this.state.selected}>
            {this.renderNavItems()}
            {this.renderExternalLinks()}
          </Nav>
          {this.renderFooterNav()}
        </SideNav>
        <div className='site-wrapper d-flex flex-column flex-fill overflow-auto'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

SiteWrapper.propTypes = {
  user: PropTypes.object,
  navigate: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default SiteWrapper
