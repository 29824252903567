import axios from 'axios'
import apiConfig from '../config'
import { getHeaders } from '../auth'

const { apiUrl, admins } = apiConfig
const searchUrl = `${apiUrl}${admins.url}${admins.search.url}`

export const importAndIndex = (indexName) => {
  const url = `${searchUrl}/${indexName}${admins.search.import}`

  return axios.post(url, {}, {
    headers: getHeaders()
  })
}

export const deleteIndex = (indexName) => {
  const url = `${searchUrl}/${indexName}${admins.search.delete}`

  return axios.delete(url, {
    headers: getHeaders()
  })
}

export const searchUsers = (query) => {
  const url = `${searchUrl}${admins.search.users}`
  const data = { query }

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

export const searchUsersByEmail = (query) => {
  const url = `${searchUrl}${admins.search.users}${admins.search.email}`
  const data = { query }

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

export const searchAll = (query, uid) => {
  const url = `${searchUrl}`
  const data = { query, uid }

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

export const getLocationsSitemap = (route, data = {}) => {
  const url = `${searchUrl}${admins.search.sitemap.url}${admins.search.sitemap.locations}${route}`

  return axios.post(url, data, {
    headers: getHeaders()
  })
}
