import { API_REQUEST, API_ERROR } from '../constants/actionTypes/notifications'
import {
  UPDATE_URL,
  TRANSCRIBE_VIDEO_REQUEST,
  TRANSCRIBE_VIDEO_SUCCESS
} from '../constants/actionTypes/transcripts'
import adminApi from '../utils/apis/admin'

export const updateVideoUrl = (url) => {
  return {
    type: UPDATE_URL,
    url
  }
}

export const transcribeVideo = (url, format) => {
  return (dispatch) => {
    dispatch({ type: TRANSCRIBE_VIDEO_REQUEST })
    dispatch({ type: API_REQUEST })

    const requestData = { url, format }

    return adminApi.transcribeYouTubeVideo(requestData)
      .then((res) => {
        const { data } = res

        dispatch({
          type: TRANSCRIBE_VIDEO_SUCCESS,
          data
        })
      })
      .catch(() => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Could not transcribe video, please try again'
          }
        })
      })
  }
}
