import {
  UPDATE_URL,
  TRANSCRIBE_VIDEO_REQUEST,
  TRANSCRIBE_VIDEO_SUCCESS
} from '../constants/actionTypes/transcripts'

const ACTION_HANDLERS = {
  [UPDATE_URL]: (state, action) => {
    const { url } = action

    return {
      ...state,
      video: {
        ...state.video,
        url
      }
    }
  },
  [TRANSCRIBE_VIDEO_REQUEST]: (state, action) => {
    return {
      ...state,
      video: {
        ...state.video,
        info: null
      }
    }
  },
  [TRANSCRIBE_VIDEO_SUCCESS]: (state, action) => {
    const { data } = action

    return {
      ...state,
      video: {
        ...state.video,
        info: {
          ...data
        }
      }
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  video: {
    url: '',
    info: null
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
