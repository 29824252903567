import DashboardContainer from '../containers/DashboardContainer'
import LoginContainer from '../containers/LoginContainer'
import AuthContainer from '../containers/AuthContainer'
import {
  HOME,
  FEATURES,
  LOGIN,
  TRANSCRIBE,
  SEARCH,
  MAIL,
  ACCOUNT,
  RETOOL
} from '../constants/routes'

const RoutePaths = [
  HOME.url,
  FEATURES.url,
  MAIL.playground,
  MAIL.compose,
  TRANSCRIBE.url,
  SEARCH.playground,
  SEARCH.import,
  SEARCH.sitemap,
  ACCOUNT.simulator,
  RETOOL.url
]

const dashboardRoutes = RoutePaths.map((path) => ({
  exact: true,
  component: DashboardContainer,
  routes: [],
  path
}))

export const routes = () => [
  {
    path: '/',
    exact: true,
    component: AuthContainer,
    isPublic: true,
    routes: []
  },
  ...dashboardRoutes,
  {
    path: LOGIN.url,
    exact: true,
    component: LoginContainer,
    isPublic: true,
    routes: []
  }
]

export default routes
