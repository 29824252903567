import { getFirstDayOfWeek } from 'common/utils/date'
import {
  UPDATE_NEWSLETTER,
  UPDATE_LOCATION,
  UPDATE_FOOD_SECTION,
  UPDATE_ADVENTURE_SECTION,
  CLEAR_NEWSLETTER,
  NEWSLETTER_CREATED
} from '../../constants/actionTypes/compose'
import { CLONE_NEWSLETTER } from '../../constants/actionTypes/newsletterHistory'
import { CLONE_AND_DELETE_SCHEDULED_EMAIL } from '../../constants/actionTypes/scheduledEmails'

const initialState = {
  createdDate: null,
  sendAt: null,
  weeklyDate: getFirstDayOfWeek(),
  personalityTypes: [],
  location: {
    id: '',
    name: '',
    backgroundImgSrc: ''
  },
  food: {
    title: '',
    description: '',
    imgSrc: '',
    address: '',
    specialties: [],
    tips: [],
    references: []
  },
  adventure: {
    title: '',
    description: '',
    imgSrc: '',
    address: '',
    specialties: [],
    tips: [],
    references: []
  }
}

const ACTION_HANDLERS = {
  [UPDATE_NEWSLETTER]: (state, action) => {
    const { attr, value } = action

    return {
      ...state,
      [attr]: value
    }
  },
  [UPDATE_LOCATION]: (state, action) => {
    const { location } = action

    return {
      ...state,
      location: {
        ...location
      }
    }
  },
  [UPDATE_FOOD_SECTION]: (state, action) => {
    const { data } = action

    return {
      ...state,
      food: {
        ...state.food,
        ...data
      }
    }
  },
  [UPDATE_ADVENTURE_SECTION]: (state, action) => {
    const { data } = action
    return {
      ...state,
      adventure: {
        ...state.adventure,
        ...data
      }
    }
  },
  [CLEAR_NEWSLETTER]: (state, action) => {
    return {
      ...initialState
    }
  },
  [NEWSLETTER_CREATED]: (state, action) => {
    return {
      ...state,
      createdDate: action.createdDate
    }
  },
  [CLONE_NEWSLETTER]: (state, action) => {
    const { data } = action

    return {
      ...data.content,
      createdDate: null,
      sendAt: null
    }
  },
  [CLONE_AND_DELETE_SCHEDULED_EMAIL]: (state, action) => {
    const data = action.newsletter

    return {
      ...data.content,
      createdDate: null,
      sendAt: null
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
