import axios from 'axios'
import apiConfig from '../config'
import { getHeaders } from '../auth'

const { apiUrl, admins } = apiConfig
const newslettersUrl = `${apiUrl}${admins.url}${admins.newsletters.url}`

export const createNewsletter = (data) => {
  return axios.post(newslettersUrl, data, {
    headers: getHeaders()
  })
}

export const createTestNewsletter = (email, content) => {
  const url = `${newslettersUrl}/test`
  const data = {
    email,
    content
  }

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

export const getScheduledNewsletters = () => {
  return axios.get(`${newslettersUrl}${admins.newsletters.scheduled}`, {
    headers: getHeaders()
  })
}

export const deleteScheduledNewsletter = (data) => {
  const url = `${newslettersUrl}${admins.newsletters.scheduled}`

  return axios.delete(url, {
    headers: getHeaders(),
    params: data
  })
}

export const getNewsletterHistory = () => {
  return axios.get(`${newslettersUrl}`, {
    headers: getHeaders()
  })
}
