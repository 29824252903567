// Firebase Web Client SDK
import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseApp = firebase.initializeApp(FIREBASE_ADMIN_PANEL_CONFIG)

export const auth = firebaseApp.auth()
export const providers = [{
  provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  scopes: [
  ],
  customParameters: {
    'hd': 'propellant.io'
  }
}]

export const getCurrentUser = () => {
  return auth.currentUser || null
}

export const getIdToken = (forceRefresh = true) => {
  const user = getCurrentUser()

  if (user) {
    return user.getIdToken(forceRefresh)
      .then((token) => {
        return token
      })
  } else {
    return new Promise((resolve, reject) => {
      resolve(null)
    })
  }
}

export default {
  auth,
  getCurrentUser,
  getIdToken
}
