export default {
  apiUrl: '/api/v1',
  admins: {
    url: '/admins',
    signIn: '/sign-in',
    newsletters: {
      url: '/newsletters',
      scheduled: '/scheduled'
    },
    contacts: {
      url: '/contacts'
    },
    mail: {
      url: '/mail',
      welcome: '/welcome',
      personalitySummary: '/personalitySummary'
    },
    transcripts: {
      url: '/transcripts',
      transcribe: '/transcribe'
    },
    features: {
      url: '/features',
      flags: '/flags'
    },
    search: {
      url: '/search',
      users: '/users',
      email: '/email',
      import: '/import',
      delete: '/delete',
      sitemap: {
        url: '/sitemap',
        locations: '/locations',
        public: '/public',
        all: '/all'
      }
    },
    accounts: {
      url: '/accounts'
    },
    contentSources: {
      url: '/contentSources',
      link: '/link',
      places: '/places'
    }
  }
}
