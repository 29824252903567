import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as AuthActionsCreator from '../actions/auth'
import { navigate } from '../actions/navigation'
import AuthPage from '../pages/AuthPage'

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActionsCreator, dispatch),
  navigate: bindActionCreators(navigate, dispatch)
})

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthPage))
