import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './LoadSplashScreen.scss'

const cx = classNames.bind(styles)
const splashClass = cx(
  'splash-screen-page-loader',
  'd-flex',
  'align-items-center',
  'justify-content-center'
)

const LoadSplashScreen = ({ children }) => (
  <div className={splashClass}>
    {children}
  </div>
)

LoadSplashScreen.propTypes = {
  children: PropTypes.node.isRequired
}

export default LoadSplashScreen
