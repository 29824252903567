import axios from 'axios'
import apiConfig from '../config'
import { getHeaders } from '../auth'
import * as emailApis from './email'
import * as featureApis from './feature'
import * as newsletterApis from './newsletter'
import * as searchApis from './search'
import * as contentSourceApis from './contentSource'

const { apiUrl, admins } = apiConfig
const adminsUrl = `${apiUrl}${admins.url}`
const contactsUrl = `${adminsUrl}${admins.contacts.url}`
const transcriptsUrl = `${adminsUrl}${admins.transcripts.url}`
const accountsUrl = `${adminsUrl}${admins.accounts.url}`

export const signIn = (token) => {
  return axios.post(`${apiUrl}${admins.url}${admins.signIn}`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export const getContacts = () => {
  const url = contactsUrl

  return axios.get(url, {
    headers: getHeaders()
  })
}

export const transcribeYouTubeVideo = (data) => {
  const url = `${transcriptsUrl}${admins.transcripts.transcribe}`

  return axios.post(url, data, {
    headers: getHeaders()
  })
}

/* ------ Account ------ */
export const createCustomUserToken = (uid) => {
  const url = `${accountsUrl}/${uid}`

  return axios.post(url, {}, {
    headers: getHeaders()
  })
}

export default {
  signIn,
  getContacts,
  createCustomUserToken,
  ...emailApis,
  ...featureApis,
  ...newsletterApis,
  ...searchApis,
  ...contentSourceApis
}
