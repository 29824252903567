import Ajv from 'ajv'
import {
  UPDATE_NEWSLETTER,
  UPDATE_LOCATION,
  UPDATE_FOOD_SECTION,
  UPDATE_ADVENTURE_SECTION,
  CLEAR_NEWSLETTER,
  NEWSLETTER_CREATED,
  NEWSLETTER_INVALID,
  TEST_NEWSLETTER_SENT
} from '../../constants/actionTypes/compose'
import { API_REQUEST, API_ERROR, SCHEMA_VALIDATION_ERROR } from '../../constants/actionTypes/notifications'
import adminApi from '../../utils/apis/admin'
import { isBeforeNow, isThreeDaysFromNow, isValidScheduledTime } from 'common/utils/date'
import newsletterSchema from 'common/configs/schemas/newsletter.json'

const ajv = new Ajv()
const validate = ajv.compile(newsletterSchema)

export const updateNewsletter = (attr, value = '') => {
  return {
    type: UPDATE_NEWSLETTER,
    attr,
    value
  }
}

export const updateLocation = (location) => {
  return {
    type: UPDATE_LOCATION,
    location
  }
}

export const updateFoodSection = (data) => {
  return {
    type: UPDATE_FOOD_SECTION,
    data
  }
}

export const updateAdventureSection = (data) => {
  return {
    type: UPDATE_ADVENTURE_SECTION,
    data
  }
}

export const clearNewsletter = () => {
  return {
    type: CLEAR_NEWSLETTER
  }
}

export const createNewsletter = (data) => {
  return (dispatch) => {
    const valid = validate(data)

    if (!valid) {
      dispatch({
        type: SCHEMA_VALIDATION_ERROR,
        alert: {
          type: 'error',
          message: 'Validation Error: You must fill out the entire form to send a newsletter.'
        }
      })
    } else {
      const sendAt = data.sendAt
      dispatch({
        type: API_REQUEST
      })

      if (isValidScheduledTime(sendAt)) {
        return adminApi.createNewsletter(data)
          .then((res) => {
            const { data } = res

            if (data.createdDate) {
              dispatch({
                type: NEWSLETTER_CREATED,
                createdDate: data.createdDate
              })
            } else {
              dispatch({
                type: NEWSLETTER_INVALID,
                alert: {
                  type: 'error',
                  message: data.message
                }
              })
            }
          })
          .catch((err) => {
            dispatch({
              type: API_ERROR,
              alert: {
                type: 'error',
                message: 'Something went wrong when trying to create your newsletter. Check the logs to find out what happened.'
              }
            })
          })
      } else {
        let message = ''

        if (isBeforeNow(sendAt)) {
          message = 'You are trying to send a newsletter for a time that is in the past.'
        } else if (isThreeDaysFromNow(sendAt)) {
          message = 'You are trying to send a newsletter for a time that is more than three days in advance. This is not allowed'
        }

        dispatch({
          type: SCHEMA_VALIDATION_ERROR,
          alert: {
            type: 'error',
            message
          }
        })
      }
    }
  }
}

export const createTestNewsletter = (email, data) => {
  return (dispatch) => {
    const valid = validate(data)

    if (!valid) {
      dispatch({
        type: SCHEMA_VALIDATION_ERROR,
        alert: {
          type: 'error',
          message: 'Validation Error: You must fill out the entire form to send a newsletter.'
        }
      })
    } else {
      const testEmailData = {
        ...data,
        sendAt: null
      }

      dispatch({
        type: API_REQUEST
      })

      return adminApi.createTestNewsletter(email, testEmailData)
        .then((res) => {
          const { data } = res

          if (data.createdDate) {
            dispatch({
              type: TEST_NEWSLETTER_SENT,
              alert: {
                type: 'info',
                message: `Test email has been sent to: ${email}, please check your inbox.`
              }
            })
          } else {
            dispatch({
              type: NEWSLETTER_INVALID,
              alert: {
                type: 'error',
                message: data.message
              }
            })
          }
        })
        .catch((err) => {
          dispatch({
            type: API_ERROR,
            alert: {
              type: 'error',
              message: 'Something went wrong when trying to create your newsletter. Check the logs to find out what happened.'
            }
          })
        })
    }
  }
}
