import { SIGN_OUT_SUCCESS, UNAUTHORIZED } from '../../constants/actionTypes/auth'
import { NEWSLETTER_INVALID, TEST_NEWSLETTER_SENT } from '../../constants/actionTypes/compose'
import { API_SUCCESS, API_ERROR, SCHEMA_VALIDATION_ERROR } from '../../constants/actionTypes/notifications'

const setAlert = (state, action) => action.alert

const ACTION_HANDLERS = {
  [SIGN_OUT_SUCCESS]: setAlert,
  [UNAUTHORIZED]: setAlert,
  [API_SUCCESS]: setAlert,
  [API_ERROR]: setAlert,
  [SCHEMA_VALIDATION_ERROR]: setAlert,
  [NEWSLETTER_INVALID]: setAlert,
  [TEST_NEWSLETTER_SENT]: setAlert
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  type: '',
  message: ''
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  // If there is no handler, reset back to initial state
  return handler ? handler(state, action) : initialState
}
