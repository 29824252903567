const newsletterUrl = '/newsletter'
const mailUrl = '/mail'
const searchUrl = '/search'
const accountUrl = '/account'

export const HOME = {
  url: '/home'
}

export const LOGIN = {
  url: '/login'
}

export const TRANSCRIBE = {
  url: '/transcribe'
}

export const FEATURES = {
  url: '/features'
}

export const NEWSLETTER = {
  url: newsletterUrl,
  compose: `${newsletterUrl}/compose`,
  scheduled: `${newsletterUrl}/scheduled`,
  activity: `${newsletterUrl}/activity`
}

export const MAIL = {
  url: mailUrl,
  compose: `${mailUrl}/compose`,
  playground: `${mailUrl}/playground`
}

export const SEARCH = {
  url: searchUrl,
  import: `${searchUrl}/import`,
  playground: `${searchUrl}/playground`,
  sitemap: `${searchUrl}/sitemap`
}

export const ACCOUNT = {
  url: accountUrl,
  simulator: `${accountUrl}/simulator`
}

export const RETOOL = {
  url: '/retool'
}
