import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import {
  Card,
  CardHeader,
  UncontrolledAlert,
  Container,
  Row,
  Col
} from 'reactstrap'
import { auth, providers } from '../../utils/firebase'

const alertIcons = {
  'primary': 'bell',
  'success': 'check',
  'danger': 'exclamation-triangle'
}

class LoginPage extends Component {
  signInSuccess () {
    if (this.props.authActions) {
      this.props.authActions.signIn()
    }
  }

  handleSignInSuccess = this.signInSuccess.bind(this)

  // Configure FirebaseUI.
  getUIConfig () {
    return {
      signInFlow: 'popup',
      signInOptions: providers,
      callbacks: {
        signInSuccessWithAuthResult: this.handleSignInSuccess
      }
    }
  }

  renderAlert () {
    let alert = null

    if (this.props.notifications.alert && this.props.notifications.alert.message) {
      const { type = 'primary', message = '' } = this.props.notifications.alert

      alert = (
        <UncontrolledAlert color={type} closeClassName='close-btn'>
          <i className={`mr-2 fal fa-${alertIcons[type]}`} />
          <span className='align-middle'>{message}</span>
        </UncontrolledAlert>
      )
    }

    return alert
  }

  render () {
    const uiConfig = this.getUIConfig()
    const alert = this.renderAlert()

    return (
      <div className='page page-single'>
        {alert}
        <Container>
          <Row>
            <Col className='col-login mx-auto'>
              <Card>
                <CardHeader>Hipherd Admin Sign In</CardHeader>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

LoginPage.propTypes = {
  notifications: PropTypes.object,
  authActions: PropTypes.object
}

export default LoginPage
