import { combineReducers } from 'redux'
import compose from './compose'
import scheduledEmails from './scheduledEmails'
import newsletterHistory from './newsletterHistory'

const newsletterReducer = combineReducers({
  compose,
  scheduledEmails,
  newsletterHistory
})

export default newsletterReducer
