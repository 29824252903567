import axios from 'axios'
import apiConfig from './config'
import firebaseUtils from '../firebase'

const { apiUrl, admins } = apiConfig
const TOKEN_NAME = 'adminToken'

export const getHeaders = () => {
  const token = getToken()

  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }
}

export const storeToken = (token) => {
  window.sessionStorage.setItem(TOKEN_NAME, token)
}

export const getToken = () => {
  return window.sessionStorage.getItem(TOKEN_NAME)
}

export const signIn = (token) => {
  return axios.post(`${apiUrl}${admins.url}${admins.signIn}`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export const getAndSetUserToken = () => {
  return firebaseUtils.getIdToken()
    .then((token) => {
      if (token) {
        storeToken(token)
      }

      return token
    })
}

export default {
  getHeaders,
  storeToken,
  getToken,
  signIn,
  getAndSetUserToken
}
