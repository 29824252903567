import { push } from 'connected-react-router'
import {
  FETCH_SCHEDULED_EMAILS,
  FETCH_SCHEDULED_EMAILS_SUCCESS,
  CLONE_AND_DELETE_SCHEDULED_EMAIL,
  DELETE_SCHEDULED_EMAIL_SUCCESS
} from '../../constants/actionTypes/scheduledEmails'
import { API_REQUEST, API_ERROR } from '../../constants/actionTypes/notifications'
import adminApi from '../../utils/apis/admin'
import { NEWSLETTER } from '../../constants/routes'

export const fetchScheduledEmails = (batchId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCHEDULED_EMAILS
    })

    return adminApi.getScheduledNewsletters()
      .then((res) => {
        const { data } = res

        dispatch({
          type: FETCH_SCHEDULED_EMAILS_SUCCESS,
          data
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to fetch the scheduled newsletters. Check the logs to find out what happened.'
          }
        })
      })
  }
}

export const cloneAndDeleteScheduledEmail = (id, newsletter) => {
  return (dispatch) => {
    const params = {
      id,
      batchId: newsletter.sendGridBatchId
    }

    dispatch({
      type: API_REQUEST
    })

    return adminApi.deleteScheduledNewsletter(params)
      .then((res) => {
        const { data } = res

        dispatch({
          type: CLONE_AND_DELETE_SCHEDULED_EMAIL,
          id: data.id,
          newsletter
        })
        dispatch(push(NEWSLETTER.compose))
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to delete this scheduled newsletter. Check the logs to find out what happened.'
          }
        })
      })
  }
}

export const deleteScheduledEmail = (id, batchId) => {
  return (dispatch) => {
    const params = {
      id,
      batchId
    }

    dispatch({
      type: API_REQUEST
    })

    return adminApi.deleteScheduledNewsletter(params)
      .then((res) => {
        const { data } = res

        dispatch({
          type: DELETE_SCHEDULED_EMAIL_SUCCESS,
          data
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to delete this scheduled newsletter. Check the logs to find out what happened.'
          }
        })
      })
  }
}
