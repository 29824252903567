import { getCurrentUser } from '../utils/firebase'
import { GET_USER, SIGN_IN_SUCCESS } from '../constants/actionTypes/auth'

const ACTION_HANDLERS = {
  [SIGN_IN_SUCCESS]: (state, action) => getCurrentUser(),
  [GET_USER]: (state, action) => getCurrentUser()
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = getCurrentUser()

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
