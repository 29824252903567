import {
  FETCH_FEATURES,
  FETCH_FEATURES_SUCCESS,
  ADD_FEATURE_SUCCESS,
  DELETE_FEATURE_SUCCESS,
  UPDATE_FEATURE_FLAG_SUCCESS,
  DELETE_FEATURE_FLAG_SUCCESS
} from '../constants/actionTypes/features'

const ACTION_HANDLERS = {
  [FETCH_FEATURES]: (state, action) => {
    // On fetch request, reset the state
    return initialState
  },
  [FETCH_FEATURES_SUCCESS]: (state, action) => {
    const { data } = action

    return {
      ...state,
      ...data
    }
  },
  [ADD_FEATURE_SUCCESS]: (state, action) => {
    const { data } = action
    const updatedFeature = { ...state }

    const featureNames = Object.keys(data)

    featureNames.forEach((featureName) => {
      const oldFeatureData = updatedFeature[featureName] || {}

      updatedFeature[featureName] = {
        ...oldFeatureData,
        ...data[featureName]
      }
    })

    return updatedFeature
  },
  [UPDATE_FEATURE_FLAG_SUCCESS]: (state, action) => {
    const { flagName, featureName, result } = action

    return {
      ...state,
      [featureName]: {
        ...state[featureName],
        [flagName]: {
          ...result
        }
      }
    }
  },
  [DELETE_FEATURE_SUCCESS]: (state, action) => {
    const { name } = action
    const features = { ...state }
    delete features[name]

    return features
  },
  // [UPDATE_FEATURE_FLAGS_SUCCESS]: updateFeatureFlags,
  [DELETE_FEATURE_FLAG_SUCCESS]: (state, action) => {
    const { featureName, flagName } = action
    const features = { ...state }
    delete features[featureName][flagName]

    return features
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = null

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
