import {
  UPDATE_EMAILS,
  UPDATE_SUBJECT,
  UPDATE_BODY,
  SEND_EMAIL_SUCCESS,
  CLEAR_EMAIL_COMPOSE
} from '../../constants/actionTypes/mail'

const ACTION_HANDLERS = {
  [UPDATE_EMAILS]: (state, action) => {
    const { emails } = action

    return {
      ...state,
      emails: [...emails]
    }
  },
  [UPDATE_SUBJECT]: (state, action) => {
    const { subject } = action

    return {
      ...state,
      subject
    }
  },
  [UPDATE_BODY]: (state, action) => {
    const { body } = action

    return {
      ...state,
      body
    }
  },
  [SEND_EMAIL_SUCCESS]: (state, action) => {
    const { createdDate } = action

    return {
      ...state,
      createdDate
    }
  },
  [CLEAR_EMAIL_COMPOSE]: (state, action) => {
    return {
      createdDate: null,
      subject: '',
      body: '',
      emails: []
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  createdDate: null,
  emails: [],
  subject: '',
  body: ''
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
