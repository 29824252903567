import { push } from 'connected-react-router'
import {
  FETCH_NEWSETTER_HISTORY,
  FETCH_NEWSETTER_HISTORY_SUCCESS,
  CLONE_NEWSLETTER
} from '../../constants/actionTypes/newsletterHistory'
import { API_ERROR } from '../../constants/actionTypes/notifications'
import adminApi from '../../utils/apis/admin'
import { NEWSLETTER } from '../../constants/routes'

export const fetchNewsletterHistory = (batchId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_NEWSETTER_HISTORY
    })

    return adminApi.getNewsletterHistory()
      .then((res) => {
        const { data } = res

        dispatch({
          type: FETCH_NEWSETTER_HISTORY_SUCCESS,
          data
        })
      })
      .catch((err) => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to fetch newsletter history. Check the logs to find out what happened.'
          }
        })
      })
  }
}

export const cloneNewsletter = (newsletter) => {
  return (dispatch) => {
    dispatch({
      type: CLONE_NEWSLETTER,
      data: newsletter
    })
    dispatch(push(NEWSLETTER.compose))
  }
}
