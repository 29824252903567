import axios from 'axios'
import apiConfig from '../config'
import { getHeaders } from '../auth'

const { apiUrl, admins } = apiConfig
const featuresUrl = `${apiUrl}${admins.url}${admins.features.url}`

export const getFeatures = () => {
  return axios.get(featuresUrl, {
    headers: getHeaders()
  })
}

export const createFeature = (featureName, data) => {
  const url = `${featuresUrl}/${featureName}`

  return axios.put(url, data, {
    headers: getHeaders()
  })
}

export const updateFeatureFlag = (featureName, flagName, data) => {
  const url = `${featuresUrl}/${featureName}${admins.features.flags}/${flagName}`

  return axios.put(url, data, {
    headers: getHeaders()
  })
}

export const deleteFeatureFlag = (name, flagName) => {
  const url = `${featuresUrl}/${name}${admins.features.flags}/${flagName}`

  return axios.delete(url, {
    headers: getHeaders()
  })
}
