import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as AuthActionsCreator from '../actions/auth'
import LoginPage from '../pages/LoginPage'

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActionsCreator, dispatch)
})

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications,
    authenticated: ownProps.authenticated
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
