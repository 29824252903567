import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS, UNAUTHORIZED } from '../constants/actionTypes/auth'
import { push, replace } from 'connected-react-router'
import firebaseUtils from '../utils/firebase'
import authApi from '../utils/apis/auth'
import { HOME, LOGIN } from '../constants/routes'

/**
 * On successful firebase sign-in, we want to make sure the user is authorized to continue.
 * If they are not authorized, we auto sign them out
 */
export const signIn = (redirectUrl = HOME.url) => {
  return (dispatch) => {
    return firebaseUtils.getIdToken()
      .then((token) => {
        if (token) {
          authApi.storeToken(token)

          return authApi.signIn(token)
            .catch((err) => {
              throw err.response
            })
        } else {
          throw {
            status: 401,
            message: 'You are not signed in, please refresh and try again'
          }
        }
      })
      .then((res) => {
        dispatch({
          type: SIGN_IN_SUCCESS
        })
        dispatch(replace(redirectUrl), true)
      })
      .catch((err) => {
        let message = err.message || 'Something went wrong when we tried to log you in, please refresh and try again'

        if (err.status) {
          switch (err.status) {
            case 401:
              message = 'You are not authorized to access this page, please refresh and try again'
              break
            case 403:
              message = 'You do not have the right privileges to access this page, please refresh and try again'
              break
            default:
          }

          // On failed authorization check, automatically sign them out
          firebaseUtils.auth.signOut()
        }

        dispatch(push(LOGIN.url, true))
        dispatch({
          type: UNAUTHORIZED,
          alert: {
            type: 'danger',
            message
          }
        })
      })
  }
}

export const signOut = () => {
  return (dispatch) => {
    return firebaseUtils.auth.signOut()
      .then((res) => {
        dispatch(push(LOGIN.url))
        dispatch({
          type: SIGN_OUT_SUCCESS,
          alert: {
            type: 'primary',
            message: 'You have signed out successfully'
          }
        })
      })
      .catch(() => {
        dispatch(push(LOGIN.url))
      })
  }
}

/**
 * Checks token state and makes api request
 *  If valid: auto refresh token and continue with request
 *  If invalid: sign the current user out
 */
export const checkAndRefreshUserToken = () => {
  return (dispatch) => {
    return authApi.getAndSetUserToken()
      .then((token) => {
        if (!token) {
          dispatch((signOut()))
        }
      })
      .catch(() => {
        dispatch(signOut())
      })
  }
}
