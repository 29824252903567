import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as AuthActionsCreator from '../actions/auth'
import * as NewsletterComposeActionsCreator from '../actions/newsletter/compose'
import * as ScheduledEmailActionsCreator from '../actions/newsletter/scheduledEmails'
import * as NewsletterHistoryActionsCreator from '../actions/newsletter/newsletterHistory'
import * as MailContactsActionsCreator from '../actions/mail/contacts'
import * as MailComposeActionsCreator from '../actions/mail/compose'
import * as TranscriptsActionsCreator from '../actions/transcripts'
import * as FeaturesActionsCreator from '../actions/features'
import * as AccountsActionsCreator from '../actions/accounts'
import * as SearchActionsCreator from '../actions/search'
import { navigate } from '../actions/navigation'
import DashboardPage from '../pages/DashboardPage'

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(AuthActionsCreator, dispatch),
    newsletter: {
      compose: bindActionCreators(NewsletterComposeActionsCreator, dispatch),
      scheduledEmails: bindActionCreators(ScheduledEmailActionsCreator, dispatch),
      newsletterHistory: bindActionCreators(NewsletterHistoryActionsCreator, dispatch)
    },
    mail: {
      contacts: bindActionCreators(MailContactsActionsCreator, dispatch),
      compose: bindActionCreators(MailComposeActionsCreator, dispatch)
    },
    accounts: bindActionCreators(AccountsActionsCreator, dispatch),
    search: bindActionCreators(SearchActionsCreator, dispatch),
    transcripts: bindActionCreators(TranscriptsActionsCreator, dispatch),
    features: bindActionCreators(FeaturesActionsCreator, dispatch)
  },
  navigate: bindActionCreators(navigate, dispatch)
})

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications,
    user: state.user,
    content: state.content,
    mail: state.mail,
    transcripts: state.transcripts,
    newsletter: state.newsletter,
    scheduledEmails: state.scheduledEmails,
    features: state.features,
    accounts: state.accounts
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage))
