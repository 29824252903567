import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SiteWrapper from '../../components/SiteWrapper'
import Loadable from '../../components/Loadable'
import { ToastContainer, toast } from 'react-toastify'
import {
  HOME,
  FEATURES,
  MAIL,
  SEARCH,
  ACCOUNT,
  RETOOL
} from '../../constants/routes'

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ '../../components/Home')
})

const Mail = Loadable({
  loader: () => import(/* webpackChunkName: "Mail" */ '../../components/Mail')
})

const FeatureFlags = Loadable({
  loader: () => import(/* webpackChunkName: "FeatureFlags" */ '../../components/FeatureFlags')
})

const ElasticSearchDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "ElasticSearchDashboard" */ '../../components/ElasticSearchDashboard')
})

const AccountSimulator = Loadable({
  loader: () => import(/* webpackChunkName: "AccountSimulator" */ '../../components/AccountSimulator')
})

const Retool = Loadable({
  loader: () => import(/* webpackChunkName: "Retool" */ '../../components/Retool')
})

class DashboardPage extends Component {
  componentDidUpdate (prevProps, prevState) {
    const alert = this.props.notifications.alert

    if (alert && alert.message) {
      toast(alert.message, {
        position: 'top-center',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: alert.type,
        draggablePercent: 70
      })
    }
  }

  handleSignout = () => {
    this.props.actions.auth.signOut()
  }

  renderPage (path) {
    if (path.includes(HOME.url)) {
      return (
        <Home {...this.props} />
      )
    } else if (path.includes(FEATURES.url)) {
      return (
        <FeatureFlags
          notifications={this.props.notifications}
          features={this.props.features}
          actions={this.props.actions.features}
          user={this.props.user} />
      )
    } else if (path.includes(MAIL.url)) {
      return (
        <Mail
          location={this.props.location}
          notifications={this.props.notifications}
          mail={this.props.mail}
          actions={this.props.actions.mail} />
      )
    } else if (path.includes(SEARCH.url)) {
      return (
        <ElasticSearchDashboard
          location={this.props.location}
          notifications={this.props.notifications}
          actions={this.props.actions.search} />
      )
    } else if (path.includes(ACCOUNT.simulator)) {
      return (
        <AccountSimulator
          notifications={this.props.notifications}
          actions={this.props.actions.accounts}
          accounts={this.props.accounts} />
      )
    } else if (path.includes(RETOOL.url)) {
      return (
        <Retool
          notifications={this.props.notifications}
          actions={this.props.actions.accounts}
          accounts={this.props.accounts} />
      )
    }

    return null
  }

  render () {
    const path = this.props.location.pathname
    const component = this.renderPage(path)

    return (
      <SiteWrapper
        user={this.props.user}
        navigate={this.props.navigate}
        location={this.props.location}
        signOut={this.handleSignout}>
        <ToastContainer />
        {component}
      </SiteWrapper>
    )
  }
}

DashboardPage.propTypes = {
  notifications: PropTypes.object,
  user: PropTypes.object,
  newsletter: PropTypes.object,
  mail: PropTypes.object,
  features: PropTypes.object,
  accounts: PropTypes.object,
  actions: PropTypes.object,
  navigate: PropTypes.func,
  location: PropTypes.object
}

export default DashboardPage
