import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { Route } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

// Manages a route from the react-router route config
class RouteWithSubroutes extends Component {
  // Scroll top restoration on route change
  componentDidMount () {
    scroll.scrollToTop({
      duration: 0
    })
  }

  render () {
    const { path, routes, exact, isPublic, store } = this.props
    const user = store.getState().user // Will be populated if everything was authenticated properly

    return (
      <Route
        path={path}
        exact={exact}
        render={props => (isPublic || user)
          ? (<this.props.component {...props} routes={routes} isPublic={isPublic} />)
          : <Redirect to={{
            pathname: '/',
            search: `?from=${path}`
          }} />}
      />
    )
  }
}

RouteWithSubroutes.defaultProps = {
  isPublic: false
}

RouteWithSubroutes.propTypes = {
  isPublic: PropTypes.bool,
  store: PropTypes.object,
  path: PropTypes.string,
  location: PropTypes.object,
  routes: PropTypes.array,
  exact: PropTypes.bool,
  component: PropTypes.func
}

export default RouteWithSubroutes
