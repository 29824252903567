import React, { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { PacmanLoader } from 'react-spinners'
import LoadSplashScreen from '../../components/LoadSplashScreen'
import firebaseUtils from '../../utils/firebase'

class AuthPage extends Component {
  componentDidMount () {
    this.removeListener = firebaseUtils.auth.onAuthStateChanged((user) => {
      const queryParams = queryString.parse(this.props.location.search)
      const from = queryParams.from

      if (user) {
        this.props.authActions.signIn(from)
      } else {
        this.props.navigate('login', true)
      }
    })
  }

  componentWillUnmount () {
    this.removeListener()
  }

  render () {
    return (
      <LoadSplashScreen>
        <PacmanLoader color='#FAB620' />
      </LoadSplashScreen>
    )
  }
}

AuthPage.propTypes = {
  location: PropTypes.object,
  authActions: PropTypes.object,
  navigate: PropTypes.func
}

export default AuthPage
