import { checkAndRefreshUserToken } from '../auth'
import { API_ERROR } from '../../constants/actionTypes/notifications'
import {
  FETCH_CONTACTS_SUCCESS
} from '../../constants/actionTypes/mail'
import adminApi from '../../utils/apis/admin'

export const fetchContacts = () => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => adminApi.getContacts())
      .then((res) => {
        const { data } = res

        dispatch({
          type: FETCH_CONTACTS_SUCCESS,
          data
        })
      })
      .catch(() => {
        dispatch({
          type: API_ERROR,
          alert: {
            type: 'error',
            message: 'Something went wrong when trying to fetch contacts. Please try again'
          }
        })
      })
  }
}
