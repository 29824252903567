import { checkAndRefreshUserToken } from './auth'
import { API_REQUEST, API_ERROR } from '../constants/actionTypes/notifications'
import { FETCH_USER_TOKEN, RESET_USER_TOKEN } from '../constants/actionTypes/accounts'
import adminApi from '../utils/apis/admin'

export const createUserToken = (uid) => {
  return (dispatch) => {
    if (!uid) {
      dispatch({
        type: API_ERROR,
        alert: {
          type: 'error',
          message: 'Please select a valid user with a user id'
        }
      })
    } else {
      dispatch({
        type: API_REQUEST
      })

      return dispatch(checkAndRefreshUserToken())
        .then(() => adminApi.createCustomUserToken(uid))
        .then((res) => {
          const { data } = res

          dispatch({
            type: FETCH_USER_TOKEN,
            data: {
              uid,
              token: data.token
            }
          })
        })
        .catch((err) => {
          dispatch({
            type: API_ERROR,
            alert: {
              type: 'error',
              message: `Error with fetching user: ${uid}. Please refresh and try again.`
            }
          })
        })
    }
  }
}

export const resetUserToken = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER_TOKEN
    })
  }
}
