import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Switch } from 'react-router-dom'
import RouteWithSubroutes from '../components/RouteWithSubroutes'

class AppContainer extends Component {
  render () {
    const { routes, store, history } = this.props

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            {routes.map((route, i) => <RouteWithSubroutes key={i} store={store} {...route} />)}
          </Switch>
        </ConnectedRouter>
      </Provider>
    )
  }
}

AppContainer.propTypes = {
  routes: PropTypes.array,
  store: PropTypes.object,
  history: PropTypes.object
}

export default AppContainer
