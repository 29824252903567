import axios from 'axios'
import apiConfig from '../config'
import { getHeaders } from '../auth'

const { apiUrl, admins } = apiConfig
const contentSourcesUrl = `${apiUrl}${admins.url}${admins.contentSources.url}`

export const createOrUpdateContentSourceFromUrl = (sourceUrl) => {
  const url = `${contentSourcesUrl}`
  const data = {
    url: sourceUrl
  }

  return axios.put(url, data, {
    headers: getHeaders()
  })
}

export const updateContentSource = (csid, data = {}) => {
  const url = `${contentSourcesUrl}/${csid}`

  return axios.put(url, data, {
    headers: getHeaders()
  })
}

export const linkPlaceWithContentSource = (gpid, sourceUrl) => {
  const url = `${contentSourcesUrl}${admins.contentSources.link}`
  const data = {
    gpid,
    url: sourceUrl
  }

  return axios.put(url, data, {
    headers: getHeaders()
  })
}

export const deleteContentSource = (csid) => {
  const url = `${contentSourcesUrl}/${csid}`

  return axios.delete(url, {
    headers: getHeaders()
  })
}

export const getAllPlacesSeenInContentSource = (csid) => {
  const url = `${contentSourcesUrl}/${csid}${admins.contentSources.places}`

  return axios.get(url, {
    headers: getHeaders()
  })
}
