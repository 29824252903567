import {
  FETCH_CONTACTS_SUCCESS
} from '../../constants/actionTypes/mail'

const ACTION_HANDLERS = {
  [FETCH_CONTACTS_SUCCESS]: (state, action) => {
    const { data } = action

    return [
      ...data
    ]
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = []

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
