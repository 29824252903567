import {
  FETCH_USER_TOKEN,
  RESET_USER_TOKEN
} from '../constants/actionTypes/accounts'

const ACTION_HANDLERS = {
  [FETCH_USER_TOKEN]: (state, action) => {
    return {
      ...action.data
    }
  },
  [RESET_USER_TOKEN]: (state, action) => {
    return {}
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
