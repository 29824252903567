import { combineReducers } from 'redux'
import alert from './alert'
import {
  API_REQUEST
} from '../../constants/actionTypes/notifications'

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case API_REQUEST:
      return true
    default:
      return false
  }
}

const notificationsReducer = combineReducers({
  alert,
  isLoading
})

export default notificationsReducer
